/*General Properties*/
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
}
* {
  font-family: "Nunito", sans-serif;
}
p {
  margin-bottom: 0;
}
.circular {
  border-radius: 50%;
}
a {
  text-decoration: none;
  color: black;
}
.flexRow {
  display: flex;
  flex-direction: row;
}
.clickable {
  cursor: pointer;
}
.flexColumn {
  display: flex;
  flex-direction: column;
}
.width100 {
  width: 100%;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.flexCenterXY {
  display: flex;
  align-items: center;
  justify-content: center;
}

.siteButton {
  border-radius: 15.864px;
  border: 1px solid #000;
  background: #fff;
  box-shadow: 2px 4px 0px 0px #000;
  font-weight: 800;
  padding: 6px 23px;
}
.socialButton > button {
  width: 100%;
  height: 100%;
  background: #fff;
  border: none;
  border-radius: 12px;
  font-weight: 800;
  padding: 13px 23px;
  display: flex;
  align-items: center;
  justify-content: left;
}
.socialButton h5 {
  font-size: 18px;
  color: #e07667;
  width: 100%;
  text-align: center;
  margin: 0;
  margin-left: 10px;
}
h5 {
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
h1.generalHeader * {
  font-size: 52;
  font-family: Roboto;
  font-weight: 700;
}
h1.generalHeader {
  font-size: 52;
  font-family: Roboto;
  font-weight: 700;
}
/* p{
  font-size: 24px;
  font-family: Roboto;
  font-weight: 800;
} */
p.subHeader {
  font-size: 24px;
  font-family: Roboto;
  font-weight: 400;
  margin-bottom: 15px;
}
h2 {
  font-size: 43px;
  font-weight: 700;
}
h3 {
  font-size: 30px;
  font-weight: 600;
  margin: 0;
}
.justAMoment img {
  width: 100%;
}
.inputText {
  border: none;
  border-radius: 9px;
  background: #fff;
  border: 1px solid black;
  padding: 10px 25px;
}
@media screen and (max-width: 370px) {
  .inputText {
    padding: 5px 10px;
    width: 80%;
  }
}
.inputText:focus {
  outline: none;
}
button.isCTA {
  border-radius: 4.2px;
  background: #e95959;
  color: white;
  padding: 10px 15px;
  border: none;
  box-shadow: 0px 5.6px 5.6px 0px rgba(233, 89, 89, 0.3);
  transition: background 0.3s;
}
button.isCTA:disabled {
  background: #faa8a8;
}
/*Footer Properties*/
footer.footer {
  background: #32309A;
  color: white;
  padding: 20px 0;

  &>div {
    padding: 0 10%;
  }

  a {
    color: white;
    width: max-content;
  }

  .social {
    background-color: #5980db;
    padding: 10px;
  }

  @media screen and (max-width: 992px) {
    a.brand {    
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }

  @media screen and (max-width: 576px) {
    .section.small-center {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }

  .container-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .section {
      flex: 1 0 25%;
      padding: 20px;
      box-sizing: border-box;
    }

    @media screen and (max-width: 992px) {
      .section {
        flex: 1 0 50%;
      }
    }

    @media screen and (max-width: 576px) {
      .section {
        flex: 1 0 100%;
      }
    }
  }
}
/*Header Properties*/
.navbar {
  display: flex;
  flex-direction: row;
  position: fixed;
  width: 100%;
  background: #32309A !important;
  z-index: 1000;
  padding: 20px 10%;
  background-repeat: no-repeat;
  justify-content: space-between;
  align-items: center;
  background-size: cover;
}
.navbar.logged {
  position: absolute;
  background-image: none;
  background: transparent !important;
}

.collapsableMenu {
  display: flex;
  justify-content: space-between;
}
.navbar.logged button.mainu {
  border: none;
  background-color: transparent;
  border-radius: 56px;
  width: 140px;
  padding: 0;
}
.navbar.logged button.mainu div {
  margin: 4px;
  border-radius: 56px;
  border: none;
  background-color: rgba(0, 0, 0, 0.67);
}
.navbar.logged button.mainu .btnIcon {
  color: white;
  margin-bottom: 0;
}
.navbar.logged button.mainu p {
  color: white;
  font-size: 18px;
}
.navbar.logged button.mainu.focused {
  border: 2px solid #ffd72a;
  background-color: transparent;
}
.navbar.logged .navbar-nav {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.navbar.logged .navBtn {
  border: none;
  box-shadow: 2px 4px 0px 0px #ffd72a;
}
.navbar.logged .profileImg {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}
.navbar.logged button.mainu.focused div {
  margin: 4px;
  border-radius: 56px;
  border: none;
  background-color: white;
}
.navbar.logged button.mainu.focused .btnIcon {
  color: black;
}
.navbar.logged button.mainu.focused p {
  color: black;
}
.navbar img {
  height: 60px;
}
.loginButton {
  text-decoration: none;
  color: red;
  font-size: 21px;
}
.menuItems {
  display: flex;
  flex-direction: row;
}
.headerItem {
  margin: 0 10px;
  font-size: 20px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  width: max-content !important;
}
.headerItem h2 {
  color: white;
  font-size: 22px;
  text-decoration: none;
  font-weight: 600;
  margin: 0;
}
.loginButton > .loginTxt {
  font-size: 21;
}
.loginButton > .loginTxt.highlightedBorder {
  border-bottom: 2px solid red;
  border-radius: 1px;
}
.contAll {
  width: 100%;
}
/*home Screen*/
.imgCont {
  position: relative;
  height: 100%;
  width: 100%;
}
.introImg {
  border-radius: 32px;
  border: 5px solid #fff;
  object-fit: cover;
  object-position: 100% 0;
  position: absolute;
}
.home .leftSide {
  display: flex;
  align-items: center;
}
.img1 {
  width: 50%;
  height: 70%;
  object-position: left;
}
.img2 {
  width: 225px;
  height: 50%;
  left: auto;
  right: 0;
  top: 20%;
  position: absolute;
  object-position: 90%;
}
.img3 {
  width: 40%;
  height: 50%;
  bottom: 0;
  left: 30%;
  object-position: 30%;
}
.limitWidth {
  max-width: 1200px;
}
.home .headerCont {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.home .secondSection {
  padding: 30px 0;
}
.secondSection .serviceDiv {
  padding: 50px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.serviceRow {
  margin-bottom: 15px;
}
.serviceDiv {
  border-radius: 30px;
  border: 2px solid black;
  background-color: white;
  transform: translateY(-60px);
}
.serviceDiv .service {
  padding: 10px;
}
.serviceDiv .innerService {
  border-radius: 17px;
  border: 2px solid black;
  padding: 15px;
  /* margin:0 15px; */
}
.innerService img {
  width: 100%;
  height: 200px;
  border-radius: 15px;
  margin-bottom: 15px;
  object-fit: cover;
}
.serviceRow {
  width: 100%;
}
.justAMoment {
  margin-top: 50px;
}

.justAMoment .textCont h2 {
  margin-top: 0;
}
.membershipExplain {
  border-radius: 20px;
  background: #FFFFFF;
  box-shadow: 13.35px 13.35px 26.7px 0px #AEAEC080;
  box-shadow: -8.9px -8.9px 26.7px 0px #FFFFFF;
  padding: 15px;
  transition: all 1s ease;
}
.membershipContainer:hover .membershipExplain {
  opacity: 0.7;
  background: #dbdbdb;
}
.membershipExplain:hover {
  scale: 1.05;
  opacity: 1 !important;
  background: #FFFFFF !important;
}

.fourthSection {
  background: #f0f0f0;
  padding: 50px 100px;
}
@media screen and (max-width: 542px) {
  .fourthSection {
    padding: 50px 30px;
  }
}
.fourthSection > p {
  margin-top: 10px;
  margin-bottom: 20px;
}
.circle {
  border-radius: 100%;
  background-color: black;
  width: 35px;
  height: 35px;
}

.slider-container {
  display: inline-block;
}

.testamonials {
  margin-bottom: 20px;
}
.tc {
  position: relative;
}
.testamonial {
  width: 95%;
  border-radius: 15px;
  border: 1px solid #000;
  flex-shrink: 0;
  flex-grow: 1;
  background: #fffcb7;
  padding: 30px 0;
  margin: 0;
}
.testamonialWrap {
  width: 100%;
  border-radius: 15px;
  flex-shrink: 0;
  flex-grow: 1;
  margin: 15px;
  padding: 2.5%;
  margin: 0;
}
.animateTest {
  transition: left 0.3s;
}
.testaCont {
  width: 80%;
  overflow: hidden;
  position: relative;
}
.testamonial img {
  width: 120px;
  height: 120px;
  margin: 30px;
  object-fit: cover;
}
.fifthSection {
  margin-top: 60px;
  margin-bottom: 60px;
}
.loginChoice {
  display: flex;
  width: 100%;
}
/*signup styling*/
.choice {
  width: 70%;
  padding: 60px 150px;
  position: relative;
  border-radius: 27px;
}
.choice img {
  bottom: 0;
  height: 130%;
  position: absolute;
  left: 0;
  transform: translateX(-50%);
}
.signupForm {
  border-radius: 33px;
  background: #e07667;
  width: 50%;
  color: white;
  padding: 20px 30px;
}
.loginColor {
  border-radius: 33px;
  border: 1px solid #000;
  background: #f5c1c1;
}
.signupForm input {
  width: 80%;
  margin: 5px 0;
}
.radio-input {
  display: none;
}

.radio-label {
  /* width: 100px; */
  /* height: 100px; */
  border: none;
  border-radius: 10px;
  background-color: #6d6d6d;
  margin-bottom: 10px;
  text-align: center;
  padding: 15px 20px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.socialCont {
  align-items: center;
}
.radio-input:checked + .radio-label {
  background-color: #333;
  color: #fff;
  border-color: #333;
}
.radioContainer {
  justify-content: space-between;
  width: 80%;
}
@media screen and (max-width: 927px) {
  .signupForm {
    width: 80%;
  }
}
@media screen and (max-width: 1150px) {
  .signupForm {
    width: 70%;
  }
}
@media screen and (max-width: 600px) {
  .signupForm {
    border-radius: 0;
    width: 100%;
  }
  .loginSignupButton {
    width: 90%;
  }
  .signupForm input {
    width: 90%;
  }
  .socialCont {
    width: 100% !important;
  }
  .choice {
    width: 100%;
    border-radius: 0;
    padding: 40px 20px;
    /* width: 100%; */
  }
  .radioContainer {
    width: 90%;
  }
}
.premiumGame {
  background-color: #333;
}
/*workbooks*/
.worksheetSection {
  width: 100%;
}
.workbooks {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.workbooks .searchBar {
  width: 80%;
}
.workbooks .searchBar input {
  width: 100%;
}
.workbooks .paginationText {
  border-radius: 50%;
  background-color: #ffd72a;
  width: 25px;
  height: 25px;
  user-select: none;
  cursor: pointer;
}
.workbookItem img {
  width: 100%;
  height: 170px;
  object-fit: cover;
  border-radius: 19.6px;
  border: 14px solid #fff;
  box-shadow: 0px 0px 8.908934593200684px 0px rgba(0, 0, 0, 0.25);
}
.workbookItem h3 {
  width: 100%;
  font-size: 18px;
  text-align: center;
}
.workbookItem > div {
  width: 80%;
  cursor: pointer;
}
.img-unclickable {
  pointer-events: none;
}
.activeWorkbook > div {
  width: 100%;
}
.maxContent {
  width: max-content;
}
.activeWorkbook > div > div > h3:nth-child(2) {
  color: #ffd72a;
  margin-left: 25px;
}
.activeWorkbook > div > h3:nth-child(2) {
  width: 70%;
}
@media screen and (min-width: 1200px) {
  .workbooks .searchBar {
    width: 50%;
  }
}
.excludeNav {
  display: none;
}
@media screen and (max-height: 750px) {
  .excludeNav {
    display: block;
  }
}
/*user logged*/
#games-page, #videos-page {
  background-image: url("/public/game-background.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.game {
  color: #fff;
  text-shadow: 0px 4px 4px #ffd72a;
  font-family: Nunito;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.game > p {
  text-align: center;
  font-size: 30px;
}
@media screen and (max-width: 768px) {
  .game > p {
    font-size: 25px;
  }
}
@media screen and (max-height: 750px) {
  .game > p {
    font-size: 20px;
  }
}
.gameRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media screen and (min-width: 879px) {
  .gameRow {
    overflow-x: hidden;
  }
}
.game > .imgCont {
  width: 100%;
  border-radius: 40px;
  width: 100% !important;
  overflow: hidden;
  height: 35vh;
}
.game > .imgCont > img {
  border-radius: 40px;
  border: 8px solid #fff;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.game {
  position: relative;
}
.premiumAbove {
  border-radius: 40px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  /* position: relative; */
}
/* Popup styling and animations */
.popupCont {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 999;
}
.statsContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  gap: 15px;
}
.statBox {
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  min-width: 250px;
  text-align: center;
}
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 999;
  animation: fadeIn 0.5s ease-in-out;
  border-radius: 15px;
}
.popup button {
  margin: 15px;
  width: -webkit-fill-available;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.crown {
  margin: 20px;
  color: gold;
}
.playHover {
  display: none;
  border-radius: 40px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
}
.playHoverNoHover {
  display: none;
  border-radius: 40px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
}
.playHover > img {
  width: 20%;
}
.game .imgCont:hover .playHover {
  display: flex;
}
.loggedHome .limitWidth {
  width: min(90%, 1000px);
}
.yellowButton {
  border: 0.931px solid #ffd72a;
  background: #fff;
  box-shadow: 1.861728310585022px 3.723456621170044px 0px 0px #ffd72a;
}
.contAll.logged {
  height: 100vh;
}
@media screen and (max-height: 900px) {
  .contAll.logged {
    height: 100%;
  }
}
.contAll.logged .limitWidth {
  height: calc(100% - 50px);
}
.contAll.logged .limitWidth.sc {
  overflow-y: scroll;
  overflow-x: hidden;
}
.contAll.logged > div:nth-child(1) {
  height: 100%;
}
@media screen and (max-width: 768px) {
  .contAll.logged > div:nth-child(1) {
    height: calc(100% - 70px);
  }
}
@media screen and (max-width: 575px) {
  .helpVideo {
    width: 100% !important;
  }
}
/*bottom navbar*/
.bottomNav {
  justify-content: space-around;
  width: 100%;
  align-items: center;
  height: 70px;
  position: fixed;
  top: calc(100vh - 70px);
  background-color: white;
}
.bottomNav > a {
  height: 100%;
  display: flex;
  justify-content: center;
  flex: 1;
}
.bottomNav > a.focused {
  background-color: #52c5ff;
}
.bottomNav .bnel {
  height: 100%;
  justify-content: space-between;
  padding-bottom: 10px;
  padding-top: 15px;
}
/* admin users */
.adminUsers .limitWidth {
  margin-inline: auto;
}
.adminUsers table {
  margin-top: 30px;
  width: 100%;
}
/*play game*/
.gameContainer {
  width: 100%;
  background-color: transparent;
  margin: 0;
  padding: 0;
  padding-bottom: 50px;
}
.gameContainer > iframe {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.gameContainer > div {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.gameContainer > div > div {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
/*
PRICING EL
*/
.card-form {
  min-width: 300px;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

/* CardElement container */
.card-element {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: transform 0.3s;
}

/* Submit button */
.card-form button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.card-form button > div {
  align-items: flex-start;
  justify-content: space-between;
}
.card-form button:disabled {
  background-color: #3a99ff;
}

.card-form button:not([disabled]):hover {
  background-color: #0056b3;
}

.StripeElement {
  /* Apply your custom styles here */
  padding: 15px; /* Increase padding for bigger text fields */
  border-radius: 8px;
  border: 1px solid #ccc;
  background-color: white; /* Add a white background */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.StripeElement--focus {
  border-color: #007bff; /* Change border color on focus */
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1); /* Add a slightly stronger shadow on focus */
}
#payment-page {
  background: #E0E0E0;

  div.container {
    gap: 15px;
    margin-bottom: 15px;
    margin-top: 15px;

    .payment-column {
      background-color: white;
      padding: 15px;
      border-radius: 20px;
      height: fit-content;
      min-height: 250px;
      box-shadow: 13.35px 13.35px 26.7px 0px #AEAEC080;
      box-shadow: -8.9px -8.9px 26.7px 0px #FFFFFF;

      h5 {
        font-family: 'Bubblegum Sans';
      }

      .clickable.link {
        color: #5980DB;
        font-weight: 500;
        text-decoration: underline;
      }
    }
  }
}
.payment.contAll {
  min-height: 100vh;
}
* {
  padding: 0;
  margin: 0;
}
.wrapper {
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}
.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}
/*gamePlay*/
.gameTitle {
  color: #fff;
  text-shadow: 0px 4px 4px #ffd72a;
  font-family: Nunito;
  font-size: 48px;
  font-style: normal;
  font-weight: 800;
  width: 100%;
  text-align: center;
  line-height: normal;
}
.blackPlay {
  filter: invert(100%);
  cursor: pointer;
  margin-left: 15px;
}
.gameCont {
  min-height: 100vh;
}
/* HELP BUTTON */
.helpCont {
  bottom: 180px;
  right: 50px;
  position: fixed;
  z-index: 1002;
}
.containerHelpBtn {
  width: 120px;
  height: 120px;
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  right: 0;
}
.helpVideo {
  width: 500px;
  height: 300px;
  background-color: white;
  position: relative;
  bottom: 40px;
  border-radius: 20px;
}
.containerHelpBtn > .square {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  background-color: #f7f7f7;
  transform: rotate(45deg);
}
.helpVideo > iframe {
  height: 90%;
  width: 90%;
  margin: 2.5% 5%;
  border-radius: 15px;
}
.containerHelpBtn > .text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #000;
  font-weight: 800;
  font-size: 20px;
  width: 100%;
  text-align: center;
}
.gamesContAdmin {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-top: 25px;
}
.adminGame {
  width: 90%;
  padding: 5px;
  background: rgba(174, 174, 174, 0.4);
  border-radius: 5px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  height: auto;
  transition: height 0.3s;
}
.adminGame > .alwaysArea {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 20px;
}
.editGameBtn {
  border: none;
  border-radius: 15px;
  padding: 5px 30px;
}
.editGameBtn > p {
  margin: 0;
}
.adminGame > .closedArea {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 20px;
}
/* .closedArea{
  display: none;
} */
.deleteBtn {
  border: none;
  color: white;
  background: red;
  padding: 10px 20px;
  border-radius: 15px;
}
.editBtn {
  border: none;
  color: white;
  background: rgb(47, 0, 255);
  padding: 10px 20px;
  border-radius: 15px;
}
.saveBtn {
  border: none;
  color: white;
  background: green;
  padding: 10px 20px;
  border-radius: 15px;
  margin-right: 20px;
}
.adminGame h2.name {
  color: #000;
  text-shadow: 0px 4px 4px #ffd72a;
  font-family: Nunito;
  font-size: 25px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.saveBtnCont {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 20px;
}
h2[contenteditable="true"] {
  border: 1px solid black;
}
.gameEditRow {
  margin-bottom: 15px;
}
.loadingTxt {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.w-3 {
  width: 30%;
}
.w-7 {
  width: 70%;
}
.userHeader {
  margin-bottom: 25px;
}
.avatarImageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.userInfoCont {
  margin-left: 50px;
  color: #fff;
  text-shadow: 0px 4px 4px #ffd72a;
  font-family: Nunito;
  font-size: 48px;
  font-style: normal;
  font-weight: 800;
  line-height: 114.9%; /* 55.152px */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
@media screen and (max-width: 433px) {
  .userInfoCont {
    align-items: flex-start !important;
    /* justify-content: flex-start; */
  }
}
@media screen and (max-width: 1200px) {
  .userHeader > .w-3 {
    width: 50%;
  }
  .userHeader > .w-7 {
    width: 50%;
  }
}
@media screen and (max-width: 760px) {
  .userHeader > .w-3 {
    width: 100%;
  }
  .userHeader {
    flex-direction: column;
  }
  .userHeader img {
    width: 30%;
  }
  .userHeader > .w-7 {
    width: 100%;
    align-items: center;
    margin-left: 0;
    margin-top: 10px;
  }
}
.subStat {
  color: #fff;
  text-shadow: 0px 4px 4px #ffd72a;
  font-family: Nunito;
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  line-height: 114.9%; /* 55.152px */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.adminGameImage {
  width: 300px;
  height: 200px;
}
.editorClassName {
  background-color: white;
  min-height: 50px;
  max-height: 300px;
}
.loadingFrag {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  flex-direction: column;
}
.loadingFrag .lds-ellipsis div {
  background-color: #333 !important;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
.pdfUpdate {
  background-color: rgb(209, 209, 209);
  border-radius: 5px;
  padding: 5px;
  justify-content: space-between;
  margin-bottom: 10px;
}
.updatePDFHeader {
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.updatePDFHeader > h3 {
  font-size: 22px;
  font-weight: 400;
}
.updatePDFHeader > div > button {
  padding: 5px 10px !important;
}
.downloadPDFBtn {
  width: max-content;
}
.pdfAttr {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  padding: 1% 2%;
  font-size: 14px;
  width: max-content;
  border-radius: 6px;
  border: 1px solid #000;
}
.loadingPDF {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.errorPDFShow * {
  color: red;
}
.successPDFShow * {
  color: green;
}
.PDFLoadingDisplay {
  width: 50%;
  border-radius: 20px;
  background-color: white;
  padding: 30px;
}
.errorTxt {
  color: red;
}
.successTxt {
  color: green;
}
.flexEnd {
  display: flex;
  justify-content: flex-end;
}
.centerThingsFlex {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0 15px 0;
}

#page-container {
  display: flex;
}

#page-content {
  flex: 1;
  height: 100%;
  margin-left: 200px;
  background-color: #f0f1f7;
  min-height: 100vh;
  position: relative;
}

#collapsed-navbar {
  display: none;
}

@media (max-width: 768px) {
  #page-content {
    margin-left: 0;
    margin-top: 110px;
  }

  #collapsed-navbar {
    display: flex;
  }
}

body.modal-open {
  overflow: hidden;
  padding-right: 0 !important;
}

#users-table tbody tr td,
#users-table tbody tr th {
  background-color: transparent !important;
}

.premium-user-cell {
  background-color: green;
}

.nonpremium-user-cell {
  background-color: red;
}

.capitalize {
  text-transform: capitalize;
}

.premium-cell {
  border-radius: 5px;
  font-weight: bolder;
  width: 60px;
}

.btn-dark-blue {
  background: linear-gradient(90deg, #2b46a8 0%, #111c44 100%);
  color: #fff;
}

.btn-dark-blue:hover {
  color: #fff;
}

.btn-premium {
  background-color: #feb502;
  transition: all 0.2s ease;
}

.btn-premium:hover {
  background-color: #da9c00;
}

#guest-navbar ul > li > a.headerItem > h2 {
  font-family: "Bubblegum Sans";
  font-weight: normal;
}

.login-btn {
  width: 120px;
  height: 45px;
  font-family: "Bubblegum Sans";
  font-weight: normal;
  font-size: 20px;
  border-radius: 4px;
  border: none;
  transition: opacity 0.3s ease;
  justify-content: center;
  display: flex;
  align-items: center;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);

}

.btn-custom-join {
  background-color: #3f309a;
  color: white;
}

.btn-custom-login {
  background-color: white;
  color: #3f309a;
}

.btn-custom-login:hover,
.btn-custom-join:hover {
  opacity: 0.6;
}

#g_a11y_announcement {
  display: none;
}

.workbook-item {
  align-items: center;
}

.workbook-item > .image-container > .image-hover > .image-description{
  display: none;
  width: 100%;
  flex: 1;
  overflow: hidden;
  text-wrap: wrap;
  text-overflow: ellipsis;
  font-size: 14px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.workbook-item > .image-container > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  object-position: top center;
  transition: object-position 2s ease-in-out;
}

.worksheet-premium-container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  
  svg {
    background-color: blue;
    padding: 10px;
    margin: 10px;
    border-radius: 50%;
    aspect-ratio: 1/1;
  }
}

.image-container:hover img {
  object-position: bottom center;
}

.image-container {
  position: relative;
  cursor: pointer;
  width: 100%;
  padding-top: 100%;
}

.image-container .image-text {
  color: black;
  font-size: 18px;
}

.image-container:hover .image-description {
  display: -webkit-box !important;
}

.image-container:hover .image-hover {
  height: 100px;
  align-content: start;
}

.image-hover {
  position: absolute;
  bottom: 0;
  background-color: #fff;
  padding: 10px;
  align-content: center;
  height: 45px;
  margin: 0;
  text-align: left;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  transition: height 0.2s;
  display: flex;
  flex-direction: column;
}

.square-image-container {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
  overflow: hidden;
}

.zoom-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.square-image-container:hover .zoom-image {
  transform: scale(1.5);
}

.square-image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
  transition: object-position 2s ease-in-out;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App > .pages {
  flex: 1;
  min-height: 100vh;

  &:has(.pages-flex) {
    display: flex;
  }
}

.gap-15 {
  gap: 15px;
}

p.justify-text {
  text-align: justify;
}

.slick-prev:before, .slick-next:before {
  color: #0d6efd !important;
  font-size: 50px !important;
}

.slick-prev {
  left: -55px !important;
}

.slick-dots li button::before {
  content: "-" !important;
  font-size: 60px !important;
  position: relative !important;
  color: #686868 !important;
}

.slick-dots li.slick-active button::before {
  color: #5980db !important;
}

.slick-dots {
  bottom: -35px !important;
}

.worksheet-stats {
  background: #D9D9D9;
  border-radius: 100px;
  padding: 10px 20px;
  height: fit-content;
}

.pages > .user-page-content {
  padding-top: 110px;
  margin: 0 10%;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  left: 50%;
  transform: translate(-50%, 10%);
}

.dropdown-content span {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.dropdown-content span:hover {
  background-color: #ddd;
}

.show {
  display: block;
}

.profile-pic-edit {
	position: relative;
	display: inline-block;
}

.profile-pic-edit:hover .edit {
	display: flex;
}

.profile-pic-edit:hover img {
	opacity: 0.4;
}

.edit {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
	position: absolute;
	display: none;
	top: 0;
}

.edit-image-button {
  background-color: white;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  width: 35%;
}

.edit-image-button:hover {
  border: 1px solid black;
}

@keyframes scale-up {
  from {
    scale: 0;
  }
  to {
    scale: 1;
  }
}

@keyframes wiggle {
  0%,
  100% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(30deg);
  }

  20% {
    transform: rotate(-25deg);
  }

  30% {
    transform: rotate(20deg);
  }

  40% {
    transform: rotate(-35deg);
  }

  50% {
    transform: rotate(40deg);
  }

  60% {
    transform: rotate(-30deg);
  }

  70% {
    transform: rotate(25deg);
  }

  80% {
    transform: rotate(-20deg);
  }

  90% {
    transform: rotate(15deg);
  }
}

#home-page .cloud {
  scale: 0;
  animation: scale-up linear forwards;
  animation-timeline: view() !important;
  animation-range: entry;
}

#home-page .music-chords-img {
  animation: wiggle linear;
  animation-timeline: view() !important;
  animation-range: cover;
}

button.read-more, #mission-statement>div>p>span.dots {
  display: none;
}

#home-page {
  h1, h2 {
    font-family: "Bubblegum Sans";
  }

  p, span.dots {
    font-family: "Poppins";
  }

  @media screen and (max-width: 576px) {
    #welcome>div, #our-membership>div {
      flex-direction: column;
      padding-bottom: 50px !important;
      text-align: center;
    }
    
    #welcome>div#welcome-pargaraph {
      padding-top: 3rem !important;
    }

    #welcome>div>* {
      align-items: center;
    }
    
    .align-center-small {
      text-align: center !important;
      align-items: center !important;
    }

    .align-center-small>div {
      text-align: left !important;
    }

    #backpack-img {
      left: 15% !important;
      width: 115px !important;
    }

    #mission-statement {
      &.collapsed>div {
        p>span.dots {
          display: inline;
        }
        
        p:not(:first-child) {
          display: none;
        }

        p:nth-child(1) {
          display: inline !important;
        }
      }

      &:not(.collapsed)>div {
        p>span.dots {
          display: none;
        }

        p {
          display: block;
        }
      }

      button.read-more {
        display: inline;
      }

      .cloud {
        width: 150px !important;
      }

      #cloud-1 {
        top: 10% !important;
      }

      #cloud-2 {
        top: 25% !important;
      }

      #cloud-3 {
        top: 55% !important;
        bottom: 0 !important;
      }

      #cloud-4 {
        top: 80% !important;
        bottom: 0 !important;
      }
    }

    #features {
      #pencil-img {
        width: 75px !important;
        bottom: 50% !important;
        top: auto !important;
      }
      
      #bus-img {
        width: 125px !important;
        bottom: -15% !important;
        top: auto !important;
      }
    }

    #testimonials {
      #scissors-img {
        width: 75px !important;
        top: 0 !important;
        bottom: auto !important;
        left: 10% !important;
      }

      #calculator-img {
        width: 75px !important;
        top: 0 !important;
        bottom: auto !important;
        right: 10% !important;
      }
    }
  }
}

#games {
  .slick-current.slick-active {
    transform: scale(1.2);
    z-index: 2;
    filter: brightness(100%) blur(0);
  }

  .slick-track {
    display: flex;
    padding: 45px 0;
    align-items: center;
  }

  .slick-dots li button::before {
    content: "-";
    font-size: 60px;
    position: relative;
    color: #686868;
  }

  .slick-dots li.slick-active button::before {
    color: #5980db;
  }

  .slick-dots {
    bottom: -35px;
  }

  .slick-slide {
    margin: 0.2% 0.4%;
    transition: all 0.4s ease-in-out;
  }

  .slick-list {
    margin: -0.2% -0.4%;
  }
}

#worksheets {
  .slick-track {
    display: flex;
  }

  .slick-slide {
    margin: 20px;
  }

  .slick-list {
    margin: -20px;
    padding: 10px 0;
  }
  
  .slick-dots li button::before {
    content: "-";
    font-size: 60px;
    position: relative;
    color: #686868;
  }
  
  .slick-dots li.slick-active button::before {
    color: #5980db;
  }
  
  .slick-dots {
    bottom: -35px;
  }

  div.worksheet-home-card {
    background-color: white;
    border-radius: 20px;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.2) 5px 5px 20px 0px;

    .downloads {
      background-color: #5980db;
      color: white;
      padding: 10px;
      border-radius: 10px;
    }
  }
}

#testimonials {
  .slick-track {
    display: flex;
  }

  .slick-slide {
    margin: 0 10px;
  }

  .slick-list {
    margin: 0 -10px;
  }

  .slick-prev:before,
  .slick-next:before {
    color: #fff !important;
    font-size: 70px !important;
  }

  .slick-prev,
  .slick-next {
    margin-top: 25px;
  }

  .slick-prev {
    left: -75px !important;
  }
}

#music {
  .slick-track {
    display: flex;
    margin: 20px 0;
  }

  .slick-slide {
    margin: 0 20px;
  }

  .slick-list {
    margin: 0 -20px;
    height: auto !important;
  }

  .slick-dots li button::before {
    content: "-";
    font-size: 60px;
    position: relative;
    color: #686868;
  }

  .slick-dots li.slick-active button::before {
    color: #5980db;
  }

  .slick-dots {
    bottom: -35px;
  }
}

#features {
  .slick-slide {
    margin: 0 10px;
    height: auto;
  }

  .slick-slide>div {
    height: 100%;
  }

  .slick-track {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
  }

  .slick-list {
    margin: 0 -10px;
  }
  
  .slick-dots li button::before {
    content: "-";
    font-size: 60px;
    position: relative;
    color: #c7c7c7;
  }

  .slick-dots li.slick-active button::before {
    color: #fff;
  }

  .slick-dots {
    bottom: -35px;
  }
}

.flip-horizontal {
  -moz-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

span.skeleton-hide-content::before {
  content: "" !important;
}

#videos-page {
  .videos-container.wide {
    grid-template-columns: repeat(4, 300px) !important;
  }

  .video-category.wide-video {
    aspect-ratio: 16/9 !important;
  }
}

#games-page, #videos-page {
  .games-container, .videos-container {
    display: grid;
    grid-template-columns: repeat(3, 200px);
    grid-auto-rows: min-content;
    grid-gap: 50px;
    height: calc(100vh - 110px);
    overflow-y: auto;
    padding: 20px 3rem 45px 3rem;
    justify-content: center;
    align-items: center;

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #6561b9;
      border-radius: 10px;
      border: 3px solid #5251bc;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #5050a0;
    }
  }

  .game-category, .video-category {
    width: 100%;
    aspect-ratio: 1/1;

    .premium-game {
      width: 100%;
      height: 100%;
      background-color: #00000060;
      border-radius: 20px;
      z-index: 40;
      font-size: 40px;
    }
  }

  @media screen and (max-width: 796px) {
    .games-container, .videos-container {
      grid-column-gap: 20px;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .my-container {
    position: relative;
    display: inline-block;
  }

  .front-div {
    width: 100%;
    height: 100%;
    z-index: 11;
    background-color: white;
    border-radius: 20px;
  }

  .front-div>.game-image {
    position: absolute;
    height: 100%;
    object-position: center;
    object-fit: cover;
    border-radius: 20px;
    z-index: 12;
  }

  .hidden-div {
    position: absolute;
    justify-content: center;
    height: 100%;
    bottom: 0;
    align-items: end;
    left: 0;
    width: 100%;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 1);
    color: black;
    text-align: center;
    padding-top: 1em;
    display: flex;
    z-index: 10;
    transition: all 0.2s ease-in-out;
    top: 0;
    box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.4);
    overflow: hidden;
  }
  
  .my-container:hover .hidden-div {
    height: calc(100% + 1.5em);
  }
  
  .play-hover {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: 20px;
    z-index: 13;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
    background-color: #00000040;
    display: flex;
    opacity: 0;
    visibility: hidden;
  }

  .play-hover img {
    height: 50%;
    transform: scale(0.8);
    transition: transform 0.3s ease;
  }

  .my-container:hover > div > .play-hover {
    opacity: 1;
    visibility: visible;
  }

  .my-container:hover > div > .play-hover img {
    transform: scale(1);
  }

  #game-types-container, #video-types-container {
    background: #21212199;
    height: calc(100vh - 110px);
    position: relative;
    display: flex;
    direction: rtl;

    #collapse-btn {
      position: absolute;
      bottom: 5%;
      right: 0;
      transform: translate(50%, -50%);
      z-index: 15;
      display: flex;
      border-radius: 50%;
      padding: 10px;
      background: white;
    }

    .content {
      justify-content: safe center;
      flex-grow: 1;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 1.5rem 3rem 1.5rem 1.5rem;
      transition: width 0.3s, border-radius 0.3s, opacity 0.3s, background 0.3s;

      &::-webkit-scrollbar {
        width: 7px;
      }
    
      &::-webkit-scrollbar-thumb {
        background-color: #5980DB;
        border-radius: 10px;
        border: 1px solid white;
      }
    
      &::-webkit-scrollbar-thumb:hover {
        background-color: #82a6fa;
      }

      .game-type-button, .video-type-button {
        transition: width 0.3s, border-radius 0.3s, opacity 0.3s, background 0.3s;
        will-change: width, border-radius, opacity;
      }

      &:not(.collapsed) .game-type-button, &:not(.collapsed) .video-type-button {
        border-radius: 10px;
        padding: 10px 20px;
        background: #5980DB;

        &:hover {
          background: #82a6fa;
        }

        &.active {
          background: #ffffff;

          p {
            color: black;
          }
        }

        img {
          display: none;
          transition: all 0.3s;
        }

        p {
          color: white;
          font-weight: 500;
          font-family: "Bubblegum Sans";
          text-align: center;
        }
      }

      &.collapsed .game-type-button, &.collapsed .video-type-button {
        width: 50px;
        border-radius: 50%;

        img {
          transition: width 0.3s, border-radius 0.3s, opacity 0.3s, background 0.3s;
          will-change: width, border-radius, opacity;
        }

        &:hover {
          opacity: 0.6;
          border-radius: 6px !important;

          img {
            border-radius: 6px !important;
          }
        }

        &.active {
          border-radius: 6px !important;

          img {
            border-radius: 6px !important;
          }
        }

        p {
          display: none;
        }

        img {
          display: flex;
          width: 50px;
          height: 50px;
          object-position: center;
          object-fit: cover;
          border-radius: 50%;
        }
      }
    }
  }

  .prePlay {
    z-index: 10 !important;
  }
}

.membership {
  #container {
    display: flex;
    flex-direction: row;
    width: 90%;
    flex: 1;
  }

  div#membership-plans {
    width: 100%;
    height: auto;
    display: flex;
    gap: 15px;

    @media screen and (max-width: 576px) {
      flex-direction: column;
    }

    .pricing-container {
      background-color: #FFFFFF;
      border-radius: 20px;
      padding: 15px 20px;
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: center;
      align-items: center;
      box-shadow: 13.35px 13.35px 26.7px 0px #AEAEC080;
      box-shadow: -8.9px -8.9px 26.7px 0px #FFFFFF;
      gap: 20px;

      button {
        width: 140px;
        height: fit-content;
        padding: 10px;
      }

      h3, h4 {
        font-family: 'BubbleGum Sans';
        font-weight: 400;
        margin: 0;
        padding: 0;
        height: min-content;
      }
    }
  }
}

@keyframes blinkHighlight {

  0%,
  100% {
    background-color: transparent;
  }

  50% {
    background-color: #d1d1d1;
  }
}

.blink-highlight {
  animation: blinkHighlight 1s ease-in-out 1;
}

.square-image {
  height: 100%;
  width: auto;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

#worksheet-images-gallery {
  img {
    &.selected-image {
      opacity: 0.8;
      border-radius: 10px;
      filter: brightness(50%);
    }
    opacity: 1;
    border-radius: 0;
    transition: all 0.3s;
  }
}

#scrollToTopBtn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  justify-content: center;
  align-items: center;
  font-size: 24px;
  cursor: pointer;
  z-index: 1000;
  opacity: 0;
  transform: translateY(20px);
  visibility: hidden;
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0s ease 0.3s;
}

#scrollToTopBtn.show {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

#scrollToTopBtn.hide {
  opacity: 0;
  transform: translateY(20px);
  visibility: hidden;
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0s ease 0.3s;
}

#scrollToTopBtn:hover {
  background-color: #0056b3;
  transform: scale(1.1);
  transition: background-color 0.3s ease, transform 0.3s ease;
}