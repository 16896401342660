#pdf-view-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
}

#total-count {
  width: 90%;
  font-size: 30px;
}

#subject-count {
  background-color: #d30000;
  color: white;
  border-radius: 5px;
  padding: 5px 15px;
  font-weight: 800;
  font-size: 12px;
  align-content: center;
}

#subject-name {
  font-weight: bolder;
  font-size: 18px;
}

.pdf-collapse {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 18px 20px;
  margin: 10px 0;
  background-color: white;
  cursor: pointer;
  border-radius: 10px;
}

.pdf-collapse > #right-side-texts > #pdf-chevron::after {
  content: "\f078";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.pdf-collapse.collapsed > #right-side-texts> #pdf-chevron::after {
  content: "\f077";
}

#subject-button {
  width: 90%;
}

#subject-button:last-of-type {
    margin-bottom: 10px;
}

#right-side-texts {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.pdf-collapse-skeleton {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 30px 10px;
  /* margin: 10px 0; */
  background-color: white;
  cursor: pointer;
  border-radius: 10px;
}