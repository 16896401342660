#login-modal .modal-body {
  display: flex;
  height: 70vh;
  min-width: 50vw;
}

#login-modal .modal-dialog {
  min-width: 50vw;
}

#login-modal .modal-content {
  border-radius: 35px;
}

#login-modal .modal-body>div>img {
  height: 100%;
  border-radius: 35px 0 0 35px;
}

.login-modal-title {
  font-family: "Bubblegum Sans";
  font-weight: normal;
  text-wrap: nowrap;
}

#login-modal #outer-div {
  position: relative;
  overflow: auto;
  height: 100%;
}

#login-modal #fixed-img {
  position: sticky;
  left: 0;
  top: 0;
  height: 100%;
}