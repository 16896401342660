#adminNav {
  width: 200px;
  background-image: linear-gradient(to bottom, #2B47A9, #111C43);
  position: fixed;
  height: 100vh;
  overflow-y: auto;
}

#collapsed-navbar {
  background-image: linear-gradient(to bottom, #2B47A9, #111C43);
}

#collapsed-navbar ul li h2 {
  color: white;
  cursor: pointer;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}

#collapsed-navbar ul li h2:hover {
  color: rgba(217, 217, 217, .1);
}

#adminNav ul li a {
  cursor: pointer;
  color: white !important;
  font-weight: 600 !important;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#adminNav ul li a:hover {
  background-color: rgba(217, 217, 217, .1);
  transition: ease-in-out all .4s; 
}

#adminNav ul li a.active {
  background-color: rgba(217, 217, 217, .14);
}

#collapsed-navbar ul li a.active > h2 {
  color: red;
}

#adminNav ul #home-img {
  width: 100%;
  display: flex;
  justify-content: center;
  scale: 0.8;
}

@media (max-width: 768px) {
  #adminNav {
    display: none;
  }
}

#adminNav::-webkit-scrollbar {
  width: 12px;
}

#adminNav::-webkit-scrollbar-thumb {
  background-color: #2B47A9;
  border-radius: 10px;
  border: 3px solid #111C43;
}

#adminNav::-webkit-scrollbar-thumb:hover {
  background-color: #4C66DA;
}
