#video-name {
  font-weight: bolder;
}

#video-item {
  width: 95%;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  margin: 0 0 10px 0;
  background-color: white;
  align-items: center;
  border-radius: 10px;
}

#video-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

#video-item-icons {
  display: flex;
  align-items: center;
}