#video-view-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
}

.video-collapse-skeleton {
  height: 175px !important;
  margin-bottom: -20px !important;
  margin-top: -20px !important;
  width: 90%
}

.video-collapse {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 18px 20px;
  margin: 10px 0;
  background-color: white;
  cursor: pointer;
  border-radius: 10px;
}

.video-collapse>#right-side-texts>#video-chevron::after {
  content: "\f078";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.video-collapse.collapsed>#right-side-texts>#video-chevron::after {
  content: "\f077";
}