#testimonials-view-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;

  .list {
    list-style-type: none;
    padding: 0;
  }

  .list-item {
    padding: 18px 20px;
    margin: 10px 0 !important;
    border-radius: 10px;
  }

  .list-item:not(span) {
    background-color: white;
    cursor: pointer;
  }
}