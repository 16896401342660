.modal-footer {
  justify-content: center;
}

.modal-header {
    background-image: linear-gradient(to right, #2B47A9, #111C43);
    height: 40px;
}

.modal-title {
    color: white;
    font-size: 15px;
}